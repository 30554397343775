import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import * as Feather from 'react-feather';
import { getRedeems, cancel } from '../../services/redeem';
import UsedTag from '../../assets/svg/tags/used-tag.svg';

const MyRedeems = (props) => {
    const [redeems, setRedeems] = useState([]);
    const [loading, setLoading] = useState(true);
    const [next, setNext] = useState(false);

    useEffect(() => {
        requestRedeems();

        return () => {
            cancel();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const requestRedeems = async () => {
        setLoading(true);
        try {
            const data = await getRedeems(next || 1);
            setNext(data.nextPage)
            setRedeems(state => [...state, ...data.redeems]);
            setLoading(false);
        } catch (err) {
        }
    };

    return (
        <>
            <div className="col-12 col-xl-8 col-xxl-9 mb-3">
                <div className="col-12 mb-5">
                    <div className="border-bottom border-3 border-gray-200 pb-1 mb-4 mb-xl-5 mt-3 mt-xl-4">
                        <h3 className="font-weight-semibold pb-xl-4">Canjes</h3>
                    </div>
                    <div className="row">
                        {
                            (!redeems || redeems.length === 0) && (
                                <div className="text-center">{
                                    (loading) ? (
                                        <Feather.Loader className="h3 mb-0 animate spin" />
                                    ) : (
                                        <p className="fw-bold">No tenés canjes para mostrar</p>
                                    )
                                }</div>
                            )
                        }
                        {
                            redeems.map((transaction, index) => (
                                <div className="col-12 col-md-6" key={`${transaction._id}_${index}`}>
                                    <div className="card rounded-12 shadow-dark-80 border border-gray-50 mb-3">
                                        {
                                            transaction.usage && <img src={UsedTag} alt="Utilizado" className="position-absolute h-75 end-0 w-auto" />
                                        }
                                        <div className="card-body px-0 p-md-3 row d-flex justify-content-center align-items-center">
                                            <div className="col-auto d-flex align-items-center">
                                                <span className="badge badge-size-xl bg-secondary p-0 rounded-24">
                                                    {
                                                        Array.isArray(transaction.promo.images) && transaction.promo.images.length > 0 &&
                                                        <img src={transaction.promo?.images[0].url} alt={transaction.name} className="rounded-24" />
                                                    }
                                                </span>
                                            </div>
                                            <div className="col ps-0 ms-4">
                                                <h6 className="mb-1 font-weight-semibold">{transaction.promo.description_title}</h6>
                                                {
                                                    !transaction.usage
                                                        ? <small className="d-block">{new Date().getTime() < new Date(transaction.expiration_date).getTime() ? 'Vence el' : 'Vencido el'} {new Date(transaction.expiration_date).toLocaleDateString('es-Ar')}</small>
                                                        : <small className="d-block"><b className="text-secondary">Utilizado el {new Date(transaction.usage_timestamp).toLocaleDateString('es-Ar')} a las {new Date(transaction.usage_timestamp).toLocaleTimeString('es-Ar', { hour: '2-digit', minute: '2-digit' })}</b></small>
                                                }
                                            </div>
                                            <div className="col-auto pe-0 pe-md-3">
                                                <div className="text-end">
                                                    <span className={
                                                        `badge rounded-pill py-2 ${transaction.usage || new Date().getTime() > new Date(transaction.expiration_date).getTime() ? 'bg-gray-100 text-gray-600' : 'bg-secondary text-white'}`
                                                    }>
                                                        {transaction.code}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                    {
                        next &&
                        <div className='text-center'>
                            {
                                loading
                                    ? <Feather.Loader className="h3 mt-3 mb-0 animate spin" />
                                    : <button className="mt-3 btn btn-lg btn-secondary" disabled={!next || loading} onClick={() => next ? requestRedeems() : null} >Ver más</button>
                            }
                        </div>
                    }
                </div>
                <div className='redeemContainer'>
                    <NavLink to="/" className="shadow-lg badge-light text-uppercase beneficios-btn">Ver Beneficios</NavLink>
                    <img src={props.assets.redeem_background_web} alt="mission icon" className='rounded-12' />
                </div>
            </div>
        </>
    )
};

const mapStateToProps = state => ({
    assets: state.assets,
});

export default connect(mapStateToProps)(MyRedeems);
