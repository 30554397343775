import assetReducer from './assets';
import authReducer from './auth';
import loadingReducer from './loading';
import loyaltyReducer from './loyalty';
import notificationsReducer from './notifications';
import {combineReducers, createStore} from 'redux';

const rootReducers = combineReducers({
  assets: assetReducer,
  auth: authReducer,
  loading: loadingReducer,
  loyalty: loyaltyReducer,
  notifications: notificationsReducer,
});

const store = createStore(rootReducers);

export default store;